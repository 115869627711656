import styled from '@emotion/styled';
import React, { FC, memo, useMemo } from 'react';
import { Dropdown } from '~/shared/components/Dropdown/Dropdown';
import { useTranslation } from '~/shared/utils/translation';
import { useIsMobile } from '~/shared/hooks/useIsMobile/useIsMobile';
import { breakpointsRaw } from '~/theme/breakpoints';

type PageOption = {
    label: string;
    value: number;
};

interface PageDropdownProps {
    currentPage: number;
    pages: number[];
    totalPages: number;
    onPageChange: (page: number) => void;
    showCheckmark?: boolean;
    'aria-label'?: string;
    variant?: 'default' | 'outlined';
}

const Wrapper = styled.div(({ theme }) => ({
    padding: `0 ${theme.space[2]}`,
}));

export const PageDropdown: FC<PageDropdownProps> = ({
    currentPage,
    totalPages,
    pages,
    onPageChange,
    showCheckmark = true,
    'aria-label': ariaLabel,
    variant = 'outlined',
}) => {
    const isMobile = useIsMobile({ width: breakpointsRaw.sm });
    const { translate } = useTranslation();

    const options = useMemo(
        () =>
            pages.map((page) => ({
                id: page,
                label: `${page}`,
                value: page - 1,
            })),
        [pages],
    );

    const renderLabel = (selectedOption: PageOption) => {
        if (isMobile) {
            return `${selectedOption.value + 1}/${totalPages}`;
        }
        return `${translate('common.page')} ${selectedOption.value + 1}/${totalPages}`;
    };

    const renderOption = (option: PageOption, isSelected: boolean) => (
        <div role="option" aria-selected={isSelected} tabIndex={0}>
            {option.label}
            {showCheckmark && isSelected && (
                <span aria-hidden="true" style={{ marginLeft: '8px' }}>
                    ✓
                </span>
            )}
        </div>
    );

    return (
        <Wrapper>
            <Dropdown
                variant={variant}
                options={options}
                selectedValue={currentPage}
                onChange={onPageChange}
                showCheckmark={showCheckmark}
                maxHeight="300px"
                minWidth="120px"
                align="center"
                labelAlign="center"
                renderLabel={renderLabel}
                renderOption={renderOption}
                aria-label={ariaLabel}
            />
        </Wrapper>
    );
};

export default memo(PageDropdown);
