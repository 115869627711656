import React, { FC, useCallback, memo, PropsWithChildren, useRef, useEffect } from 'react';
import RefinementEmptyList from './RefinementEmptyList';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import {
    DropdownInputField,
    DropDownItemButton,
    DropdownItemContainer,
    DropdownItemText,
    DropdownTextWrapper,
    DropdownValueWrapper,
    FilterList,
    StyledCheckmark,
    StyledCheckmarkContainer,
} from './styled';
import { RefinementProps } from './RefinementProps';
import { FilterDropdownExpand } from './FilterDropdownExpand';
import { RefinementListItem } from '~/features/productList/types';
import { useDebounce } from 'react-use';
import useOptimizedRefinementList from '~/shared/hooks/useOptimizedRefinementList';
import { useRefinementList } from 'react-instantsearch';

type FilterListProps = PropsWithChildren<{
    items: RefinementListItem[];
    shouldShowCount?: boolean;
    onRefine(value: string): void;
}>;

const RefinementListItems: FC<FilterListProps> = ({
    children,
    items,
    shouldShowCount,
    onRefine,
}) => {
    const { translate } = useTranslation();

    const getLabel = useCallback(
        (label: string) => {
            switch (label) {
                case 'true':
                    return translate('pdp.spec.true');
                case 'false':
                    return translate('pdp.spec.false');
                default:
                    return label;
            }
        },
        [translate],
    );

    return (
        <FilterList column>
            {items.map(({ label, value, count, isRefined }) => (
                <DropdownItemContainer grow key={`${label}-${value}`}>
                    <DropDownItemButton
                        fullWidth
                        variant="plain"
                        isRefined={isRefined}
                        onClick={() => onRefine(value)}
                    >
                        <DropdownItemText grow alignItems="flex-start">
                            <DropdownTextWrapper> {getLabel(label)}</DropdownTextWrapper>
                            <DropdownValueWrapper>
                                {shouldShowCount && count ? ` (${count})` : ''}
                                <StyledCheckmarkContainer>
                                    {isRefined && <StyledCheckmark />}
                                </StyledCheckmarkContainer>
                            </DropdownValueWrapper>
                        </DropdownItemText>
                    </DropDownItemButton>
                </DropdownItemContainer>
            ))}

            {children}
        </FilterList>
    );
};

const MemoizedRefinementListItems = memo(RefinementListItems);

type Props = Omit<RefinementProps, 'label'> & {
    showCount?: boolean;
    isSearchable?: boolean;
    searchText?: string;
    onSearch?: (value: string) => void;
    onRefine?: (value: string) => void;
    searchPlaceholder?: string;
};

export const RefinementList: FC<Props> = (props) => {
    const {
        attribute,
        showCount,
        isSearchable,
        searchText,
        onRefine,
        onSearch,
        searchPlaceholder,
    } = props;

    const { data: frame } = useFrame();
    const { filterEvent } = useEvents(frame);

    const { refine, searchForItems, items, isShowingMore, toggleShowMore, limit } =
        useOptimizedRefinementList({
            attribute,
        });

    const { items: allItems } = useRefinementList({ limit: 1000, attribute });
    const handleRefine = useCallback(
        (value: string) => {
            if (!value) return;

            filterEvent(`Filtering`, value.toString());

            refine(value);
            onRefine?.(value);
        },
        [onRefine],
    );

    const handleSearch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onSearch?.(event.target.value);
        },
        [onSearch],
    );

    useDebounce(
        () => {
            searchForItems(searchText ?? '');
        },
        200,
        [searchText],
    );

    const showMoreFilterCount = Math.max(0, allItems.length - limit);
    const shouldShowExpandButton = items.length >= limit;

    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        listRef.current?.querySelectorAll('button')?.[0].focus();
    }, [isShowingMore]);

    return (
        <>
            {isSearchable && (
                <DropdownItemContainer grow>
                    <DropdownInputField
                        placeholder={searchPlaceholder}
                        value={searchText}
                        onChange={handleSearch}
                    />
                </DropdownItemContainer>
            )}

            <div ref={listRef}>
                <MemoizedRefinementListItems
                    items={items}
                    shouldShowCount={showCount}
                    onRefine={handleRefine}
                >
                    {shouldShowExpandButton && (
                        <FilterDropdownExpand
                            onClick={toggleShowMore}
                            isExpanded={isShowingMore}
                            filterCount={showMoreFilterCount}
                        />
                    )}
                    {items && items.length === 0 && <RefinementEmptyList />}
                </MemoizedRefinementListItems>
            </div>
            {/* <FilterList column>
                {items.map((d) => (
                    <DropdownItemContainer grow key={`${d.label}-${d.value}`}>
                        <DropDownItemButton
                            fullWidth
                            variant="plain"
                            isRefined={d.isRefined}
                            onClick={() => handleRefine(d.value)}
                        >
                            <DropdownItemText grow alignItems="flex-start">
                                {renderLabel(d.label, translate)}
                                {showCount && d.count ? ` (${d.count})` : ''}
                            </DropdownItemText>
                            {d.isRefined && <StyledCheckmark />}
                        </DropDownItemButton>
                    </DropdownItemContainer>
                ))}
                {items.length >= minimumItemCount && (
                    <FilterDropdownExpand onClick={toggleShowMore} isExpanded={isShowingMore} />
                )}
                {items && items.length === 0 && <RefinementEmptyList />}
            </FilterList> */}
        </>
    );
};

export default RefinementList;
