import { usePage } from '~/templates/pages';
import { generatePageNumbers } from '~/shared/utils/generatePageNumbers';
import qs from 'qs';
/*
 ** NOTE: Following imported hooks seems not working as expected,
 ** resorted to asPath instead to get page number and usePage..nbPages for total pages count. IMC-653 PR#3634
 */

// import { useStats } from '../../hooks/useStats';
// import { useInstantSearchContext } from 'react-instantsearch';

const updatePageParam = (url: string | undefined, newPage: number): string => {
    if (!url) return `?page=${newPage}`;

    const [baseUrl, queryString] = url.split('?');
    const params = qs.parse(queryString || '');

    // Update the page parameter
    params.page = newPage.toString();

    // Use proper encoding settings in qs.stringify
    const newQueryString = qs.stringify(params, {
        arrayFormat: 'brackets', // Use brackets format for arrays
        encode: true, // Enable encoding
        encodeValuesOnly: true, // Only encode values, not keys
    });

    return `${baseUrl}?${newQueryString}`;
};

// Get 1-based page number from URL more robustly
const getPageFromUrl = (path?: string): number => {
    if (!path) return 1;

    const queryString = path.split('?')[1];
    if (!queryString) return 1;

    const params = qs.parse(queryString);
    const page = Number(params.page);

    return isNaN(page) ? 1 : page;
};

export const SSRPagination = ({ asPath }: { asPath?: string }) => {
    const { data } = usePage();

    if (!data?.plpResult) return null;

    const totalPage = data.plpResult.serverState?.initialResults.products.results[0]?.nbPages;

    const urlPageNumber = getPageFromUrl(asPath);

    // Convert to 0-based for generatePageNumbers
    const currentPage = urlPageNumber - 1;

    if (totalPage <= 1) return null;

    return (
        <div style={{ display: 'none' }}>
            {urlPageNumber > 1 && <a href={updatePageParam(asPath, urlPageNumber - 1)}>Previous</a>}
            {generatePageNumbers(currentPage, totalPage).map((pageNum) => (
                <a key={pageNum} href={updatePageParam(asPath, pageNum)}>
                    {pageNum}
                </a>
            ))}
            {urlPageNumber < totalPage && (
                <a href={updatePageParam(asPath, urlPageNumber + 1)}>Next</a>
            )}
        </div>
    );
};
