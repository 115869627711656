import React, { useState, ReactNode, useEffect, useRef, KeyboardEvent } from 'react';
import { useClickAway } from 'react-use';
import {
    DropdownContainer,
    Label,
    OptionsContainer,
    Option,
    StyledChevron,
    Checkmark,
} from './styled';

// Use generics for type safe and avoid forced assertion
type Option<T> = {
    id: string | number;
    label: string;
    value: T;
};

export type DropdownVariant = 'default' | 'outlined';

interface DropdownProps<T> {
    className?: string;
    options: Option<T>[];
    selectedValue: T;
    onChange: (value: T) => void;
    renderLabel?: (selectedOption: Option<T>) => ReactNode;
    renderOption?: (option: Option<T>, isSelected: boolean) => ReactNode;
    showCheckmark?: boolean;
    maxHeight?: string;
    minWidth?: string;
    align?: 'left' | 'center' | 'right';
    labelAlign?: 'left' | 'center' | 'right';
    'aria-label'?: string; // Added ARIA label prop
    variant?: DropdownVariant;
}

export function Dropdown<T>({
    options,
    selectedValue,
    onChange,
    renderLabel,
    renderOption,
    showCheckmark = true,
    maxHeight,
    minWidth = '80px',
    align = 'right',
    labelAlign = 'left',
    'aria-label': ariaLabel,
    variant = 'default',
}: DropdownProps<T>) {
    const [isVisible, setIsVisible] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const selectedOption = options.find((opt) => opt.value === selectedValue);

    const toggleDropdown = () => setIsVisible((prev) => !prev);

    const handleOptionClick = (value: T) => {
        onChange(value);
        setIsVisible(false);
    };

    const dropdownRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);

    useClickAway(dropdownRef, () => {
        if (isVisible) {
            setIsVisible(false);
            setFocusedIndex(-1);
        }
    });

    const handleKeyDown = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'Enter':
            case ' ':
                if (!isVisible) {
                    setIsVisible(true);
                    setFocusedIndex(0);
                } else if (focusedIndex >= 0) {
                    handleOptionClick(options[focusedIndex].value);
                }
                e.preventDefault();
                break;
            case 'ArrowDown':
                if (isVisible) {
                    setFocusedIndex((prev) => (prev < options.length - 1 ? prev + 1 : prev));
                    e.preventDefault();
                }
                break;
            case 'ArrowUp':
                if (isVisible) {
                    setFocusedIndex((prev) => (prev > 0 ? prev - 1 : prev));
                    e.preventDefault();
                }
                break;
            case 'Escape':
                setIsVisible(false);
                setFocusedIndex(-1);
                break;
            case 'Tab':
                if (isVisible) {
                    setIsVisible(false);
                    setFocusedIndex(-1);
                }
                break;
        }
    };

    useEffect(() => {
        if (isVisible && focusedIndex >= 0) {
            const optionElements = optionsRef.current?.querySelectorAll('[role="option"]');
            if (optionElements?.[focusedIndex]) {
                (optionElements[focusedIndex] as HTMLElement).scrollIntoView({ block: 'nearest' });
            }
        }
    }, [focusedIndex, isVisible]);

    return (
        <DropdownContainer
            ref={dropdownRef}
            onKeyDown={handleKeyDown}
            role="combobox"
            aria-expanded={isVisible}
            aria-haspopup="listbox"
            aria-label={ariaLabel}
        >
            <Label
                variant={variant}
                onClick={toggleDropdown}
                role="button"
                aria-haspopup="true"
                aria-expanded={isVisible}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        toggleDropdown();
                    }
                }}
            >
                {renderLabel?.(selectedOption!) ?? selectedOption?.label}
                <StyledChevron isOpen={isVisible} />
            </Label>
            <OptionsContainer
                visible={isVisible}
                maxHeight={maxHeight}
                minWidth={minWidth}
                align={align}
                role="listbox"
                aria-label="Available pages"
            >
                {options.map((option, index) => (
                    <Option
                        key={option.id}
                        selected={option.value === selectedValue}
                        onClick={() => handleOptionClick(option.value)}
                        onMouseEnter={() => setFocusedIndex(index)}
                        focused={focusedIndex === index}
                        labelAlign={labelAlign}
                        role="option"
                        aria-selected={option.value === selectedValue}
                        tabIndex={isVisible ? 0 : -1}
                    >
                        {renderOption?.(option, option.value === selectedValue) ?? (
                            <>
                                {option.label}
                                {showCheckmark && option.value === selectedValue && (
                                    <Checkmark aria-hidden="true">✓</Checkmark>
                                )}
                            </>
                        )}
                    </Option>
                ))}
            </OptionsContainer>
        </DropdownContainer>
    );
}

Dropdown.displayName = 'Dropdown';
