import styled from '@emotion/styled';
import React, { memo, useMemo } from 'react';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';
import { _transformAlgoliaToProductDetails } from '../../Algoliga.utils';
import { useTranslation } from '~/shared/utils/translation';
import { useUtils } from '~/shared/hooks/useUtils';
import { useFrame } from '~/shared/utils';
import { ProductCard } from '~/shared/components';
import { EventIdentifier } from '~/shared/hooks/useEvents';
import { ProductEventPayload } from '../../PLPModule.definitions';

const ProductHitWrapper = styled.div(() => ({
    minWidth: 0,
}));

interface ProductHitProps {
    hit: TProductDetails;
    position: number;
    identifier: string;
    isFavourite?(id: string): boolean;
    onFavourite?(product: TProductDetails): void;
    onBuyButtonClick?(identifier: EventIdentifier, data: ProductEventPayload): void;
}

/**
 * This wrapper will help prevent the ProductCard from re-rendering inside the ProductList
 * and reduce unnecessary recalculations and transformations.
 */
const ProductHit: React.FC<ProductHitProps> = ({
    hit,
    identifier,
    isFavourite,
    onBuyButtonClick,
    ...rest
}) => {
    const { translate } = useTranslation();
    const { convertPriceToLocale } = useUtils();
    const { data: frameData } = useFrame();

    const product = useMemo(
        () =>
            _transformAlgoliaToProductDetails(
                hit,
                translate,
                convertPriceToLocale,
                frameData?.productSettings,
            ),
        [hit.id],
    );

    return (
        <ProductHitWrapper>
            <ProductCard
                {...product}
                {...rest}
                imageLoading="lazy"
                listIdentifier={identifier}
                isFavourite={isFavourite?.(product.id)}
                onBuyBtn={onBuyButtonClick}
            />
        </ProductHitWrapper>
    );
};

export default memo(ProductHit);
