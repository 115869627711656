import React, {
    FC,
    memo,
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import RefinementEmptyList from './RefinementEmptyList';
import { RefinementDropdown } from './RefinementDropdown';
import { FilterDropdownExpand } from './FilterDropdownExpand';
import { RefinementProps } from './RefinementProps';
import {
    DropDownItemButton,
    DropdownInputField,
    DropdownItemContainer,
    DropdownItemText,
    DropdownTextWrapper,
    DropdownValueWrapper,
    FilterList,
    StyledCheckmark,
    StyledCheckmarkContainer,
} from './styled';
import { useFrame } from '~/shared/utils';
import { useEvents } from '~/shared/hooks/useEvents';
import { SwatchColor } from '~/shared/components';
import useColorSwatches from '~/features/productList/hooks/useColorSwatches';
import { RefinementListItem } from '~/features/productList/types';
import useOptimizedRefinementList from '~/shared/hooks/useOptimizedRefinementList';
import { useDebounce } from 'react-use';
import { useRefinementList } from 'react-instantsearch';

type FilterListProps = PropsWithChildren<{
    items: RefinementListItem[];
    shouldShowCount?: boolean;
    onRefine(value: string): void;
}>;

const RefinementListItems: FC<FilterListProps> = ({
    children,
    items,
    shouldShowCount,
    onRefine,
}) => {
    const { colorSwatches } = useColorSwatches();

    return (
        <FilterList column>
            {items.map(({ label, value, isRefined, count }) => {
                const imgSrc = colorSwatches?.find((swatch) => swatch.key === value)?.imageUrl;

                return (
                    <DropdownItemContainer grow key={`${label}-${value}`}>
                        <DropDownItemButton
                            fullWidth
                            variant="plain"
                            isRefined={isRefined}
                            onClick={() => onRefine(value)}
                        >
                            <SwatchColor src={imgSrc} alt={label} />

                            <DropdownItemText grow alignItems="flex-start">
                                <DropdownTextWrapper> {label}</DropdownTextWrapper>
                                <DropdownValueWrapper>
                                    {shouldShowCount && count ? ` (${count})` : ''}
                                    <StyledCheckmarkContainer>
                                        {isRefined && <StyledCheckmark />}
                                    </StyledCheckmarkContainer>
                                </DropdownValueWrapper>
                            </DropdownItemText>
                        </DropDownItemButton>
                    </DropdownItemContainer>
                );
            })}

            {children}
        </FilterList>
    );
};

const MemoizedRefinementListItems = memo(RefinementListItems);

type Props = RefinementProps & {
    searchable?: boolean;
    showCount?: boolean;
    placeholder?: string;
};

export const RefinementListColor: FC<Props> = ({
    attribute,
    searchable,
    showCount = true,
    label,
    placeholder,
}) => {
    const { data: frame } = useFrame();
    const { filterEvent } = useEvents(frame);
    const listRef = useRef<HTMLDivElement>(null);

    const [searchValue, setSearchValue] = useState('');

    const { refine, searchForItems, items, isShowingMore, toggleShowMore, limit } =
        useOptimizedRefinementList({
            attribute,
        });

    const { items: allItems } = useRefinementList({ limit: 1000, attribute });

    const handleRefine = useCallback((value: string) => {
        if (!value) return;

        filterEvent('Filtering', value.toString());
        refine(value);
        setSearchValue('');
    }, []);

    const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }, []);

    const onClose = useCallback((isOpen: boolean) => {
        if (!isOpen) setSearchValue('');
    }, []);

    useDebounce(
        () => {
            searchForItems(searchValue);
        },
        200,
        [searchValue],
    );

    useEffect(() => {
        listRef.current?.querySelectorAll('button')?.[0].focus();
    }, [isShowingMore]);

    const showMoreFilterCount = Math.max(0, allItems.length - limit);
    const shouldShowExpandButton = items.length >= limit;

    return (
        <RefinementDropdown label={label} onChange={onClose}>
            {searchable && (
                <DropdownItemContainer grow>
                    <DropdownInputField
                        placeholder={placeholder}
                        value={searchValue}
                        onChange={handleSearch}
                    />
                </DropdownItemContainer>
            )}

            <div ref={listRef}>
                <MemoizedRefinementListItems
                    items={items}
                    shouldShowCount={showCount}
                    onRefine={handleRefine}
                >
                    {shouldShowExpandButton && (
                        <FilterDropdownExpand
                            onClick={toggleShowMore}
                            isExpanded={isShowingMore}
                            filterCount={showMoreFilterCount}
                        />
                    )}
                    {items && items.length === 0 && <RefinementEmptyList />}
                </MemoizedRefinementListItems>
            </div>
        </RefinementDropdown>
    );
};
