import React, { useRef } from 'react';
import { FilterDropdownButton } from './FilterDropdownButton';
import { useState } from 'react';
import { FilterDropdown, RefinementContainer } from './styled';
import { useDebounce, useWindowSize } from 'react-use';

type RefinementDropdownProps = React.PropsWithChildren<{
    label?: string;
    onChange?: (isOpen: boolean) => void;
}>;
export const RefinementDropdown = ({ label, children, onChange }: RefinementDropdownProps) => {
    const [isOpen, baseSetIsOpen] = useState(false);
    const dropdown = useRef<HTMLButtonElement>(null);
    const [isRightAligned, setIsRightAligned] = useState(false);
    const { width } = useWindowSize();

    useDebounce(
        () => {
            if (!dropdown?.current) return;
            const elm = dropdown.current;
            const rect = elm.getBoundingClientRect();
            setIsRightAligned(rect.right - rect.width + 450 > width);
        },
        100,
        [width],
    );

    const setIsOpen = (isOpen: boolean) => {
        baseSetIsOpen(isOpen);
        onChange?.(isOpen);
    };

    return (
        <RefinementContainer
            tabIndex={isOpen ? 0 : -1}
            onBlur={(e) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    setIsOpen(false);
                }
            }}
        >
            <FilterDropdownButton ref={dropdown} isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                {label}
            </FilterDropdownButton>

            <FilterDropdown isOpen={isOpen} align={isRightAligned ? 'right' : 'left'}>
                {children}
            </FilterDropdown>
        </RefinementContainer>
    );
};
