export const generatePageNumbers = (
    currentPage: number, // 0-based!
    totalPages: number,
    options: { numberOfPages?: number } = {},
): number[] => {
    const { numberOfPages = 5 } = options;

    // Input validation
    if (totalPages < 1) return [];
    if (currentPage < 0) currentPage = 0;
    if (currentPage >= totalPages) currentPage = totalPages - 1;

    // If total pages is less than or equal to numberOfPages, return all pages
    if (totalPages <= numberOfPages) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const delta = 2;
    const range = [];

    let start = Math.max(0, currentPage - delta);
    let end = Math.min(totalPages - 1, currentPage + delta);

    const pagesShown = end - start + 1;
    if (pagesShown < numberOfPages) {
        if (currentPage < totalPages / 2) {
            end = Math.min(totalPages - 1, end + (numberOfPages - pagesShown));
        } else {
            start = Math.max(0, start - (numberOfPages - pagesShown));
        }
    }

    for (let i = start; i <= end; i++) {
        range.push(i + 1);
    }

    return range;
};
