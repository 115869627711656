import styled from '@emotion/styled';
import { Chevron } from '~/shared/components/Chevron/Chevron';
import { DropdownVariant } from './Dropdown';

interface LabelProps {
    variant?: DropdownVariant;
}

export const DropdownContainer = styled.div(({ theme }) => ({
    position: 'relative',
    padding: `${theme.space[2]} 0`,
}));

export const Label = styled.div<LabelProps>(({ theme, variant = 'default' }) => {
    if (variant === 'outlined') {
        return {
            position: 'relative' as const,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            color: theme.colors.black,
            fontSize: theme.fontSizes.md,
            fontFamily: theme.fonts.regular,
            padding: `${theme.space[3]} ${theme.space[2]}`,
            lineHeight: theme.lineHeights.compact,
            border: `1px solid ${theme.colors.brownLight}`,
            borderRadius: theme.sizes.cornerRadiusLarge,
            transition: 'all 0.2s ease',
            '&:hover': {
                borderColor: theme.colors.brownMedium,
                backgroundColor: theme.colors.brownUltraLight,
            },
            ':focus-visible': {
                boxShadow: theme.shadows.accessibility,
            },
        };
    }

    return {
        position: 'relative' as const,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.black,
        fontSize: theme.fontSizes.xs,
        fontFamily: theme.fonts.regular,
        '&:hover, &:focus': {
            textDecoration: 'underline',
        },
        ':focus-visible': {
            boxShadow: theme.shadows.accessibility,
        },
    };
});

export const OptionsContainer = styled.div<{
    visible: boolean;
    maxHeight?: string;
    minWidth?: string;
    align?: 'left' | 'center' | 'right';
}>(({ theme, visible, maxHeight, minWidth, align }) => {
    const getAlignmentStyles = () => {
        switch (align) {
            case 'left':
                return { left: 0 };
            case 'center':
                return {
                    left: '50%',
                    transform: 'translateX(-50%)',
                };
            case 'right':
            default:
                return { right: 0 };
        }
    };

    return {
        position: 'absolute',
        ...getAlignmentStyles(),
        top: `calc(100% + ${visible ? theme.space[3] : theme.space[2]})`,
        zIndex: theme.zIndices['1'],
        width: 'max-content',
        minWidth: minWidth || '80px',
        maxHeight: maxHeight || 'auto',
        border: `1px solid ${theme.colors.brownUltraLight}`,
        borderRadius: theme.sizes.cornerRadius,
        boxShadow: visible ? theme.shadows.floating : 'none',
        visibility: visible ? 'visible' : 'hidden',
        opacity: visible ? '1' : '0',
        background: theme.colors.white,
        transition: visible
            ? 'opacity 0.1s, top 0.2s, box-shadow 0.2s, visibility 0s'
            : 'opacity 0.1s 0.1s, top 0.2s, box-shadow 0.2s, visibility 0.2s',
        overflowY: 'auto',
    };
});

interface OptionProps {
    selected: boolean;
    focused?: boolean; // Make focused optional
    labelAlign: string;
}

export const Option = styled.div<OptionProps>(
    ({ theme, selected, focused = false, labelAlign }) => ({
        display: 'flex',
        justifyContent: labelAlign || 'space-between',
        alignItems: 'center',
        padding: theme.space[3],
        background: selected ? theme.colors.grey10 : theme.colors.white,
        fontWeight: selected ? theme.fontWeights.medium : theme.fontWeights.regular,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        '&:hover': {
            background: theme.colors.grey10,
        },
        '&:focus': {
            outline: `2px solid ${theme.colors.primary}`,
            outlineOffset: '-2px',
        },
        backgroundColor: focused ? theme.colors.grey10 : 'transparent',
    }),
);

export const StyledChevron = styled(Chevron)(({ theme }) => ({
    marginLeft: theme.space[2],
    padding: theme.space[1],
    width: 22,
    height: 22,
    color: theme.colors.brownMedium,
}));

export const Checkmark = styled.span(({ theme }) => ({
    color: theme.colors.black70,
    marginLeft: theme.space[2],
}));
