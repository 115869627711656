import styled from '@emotion/styled';
import { breakpoints } from '~/theme/breakpoints';
import { Button } from '~/shared/components';
import { lighten } from 'color2k';

export const LoadingOverlay = styled.div(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'inherit',
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out',

    '[data-loading="true"] &': {
        opacity: 1,
        visibility: 'visible',
    },

    span: {
        animation: 'pulse 1.5s infinite',
    },

    '@keyframes pulse': {
        '0%': {
            opacity: 0.4,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0.4,
        },
    },
}));

export const ProductListWrapper = styled.div(() => ({
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
}));

export const PaginationControls = styled.nav(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.space[2],
    margin: `${theme.space[5]} 0`,
    padding: theme.space[2],
    width: '100%',
    [breakpoints.xs]: {
        flexWrap: 'wrap', // Allow wrapping on smaller screens
    },
}));

// The StyledButton extends the base Button component with custom styles for the current page
export const StyledButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    transition: 'opacity 0.2s ease-in-out, background-color 0.4s ease-in-out',

    '&[aria-current="page"]': {
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        pointerEvents: 'none',
    },

    '&[data-loading="true"]': {
        cursor: 'default',
        opacity: 0.8,
    },
}));

export const NavigationButton = styled(Button)(({ theme }) => ({
    '&:focus': {
        background: lighten(theme.colors.black, 0.3),
    },
    '&:hover': {
        backgroundColor: lighten(theme.colors.black, 0.2),
    },
}));

export const FadeWrapper = styled.div<{ isLoading: boolean }>(({ isLoading }) => ({
    width: '100%',
    transition: 'opacity 0.3s ease-in-out',
    opacity: isLoading ? 0.6 : 1,
}));

export const PaginationWrapper = styled.div(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',

    [breakpoints.sm]: {
        '.pagination-controls': {
            flexWrap: 'nowrap',
        },
    },
}));
