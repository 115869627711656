import React, { useState, useEffect, FC, memo, useRef } from 'react';
import { useHits, useInstantSearch, usePagination } from 'react-instantsearch';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';
import { EventIdentifier, useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import HitsHeader from '../HitsHeader/HitsHeader';
import { ProductList } from '../ProductList/ProductList';
import { _transformProductDetailsToDataLayer } from '~/shared/hooks/useEvents/helpers';
import {
    ProductListWrapper,
    PaginationControls,
    NavigationButton,
    LoadingOverlay,
    FadeWrapper,
    PaginationWrapper,
} from './styled';
import useTransitionedValue from '~/shared/hooks/useTransitionedValue';
import { useGetTotalHits } from '~/shared/utils/useGetTotalHits';
import PageDropdown from './PageDropdown';
import { useBannerState } from '~/features/navigation/hooks/useBannerState';
import { useIsMobile } from '~/shared/hooks/useIsMobile/useIsMobile';

interface PaginatedHitsProps {
    identifier: EventIdentifier;
    query?: string | string[];
    itemsPerPage?: number;
    filterBarRef?: React.RefObject<HTMLElement>;
}

const PaginatedHits: FC<PaginatedHitsProps> = ({
    identifier,
    query,
    itemsPerPage = 20,
    filterBarRef,
}) => {
    const { translate } = useTranslation();
    const { data: frame } = useFrame();
    const { productsImpressionsEvent } = useEvents(frame);
    const { isPlusMember } = useCustomer();
    const { setTotalHits } = useGetTotalHits();
    const productListRef = useRef<HTMLDivElement>(null);
    const { headerHeight } = useBannerState();

    const [isEventTriggered, setIsEventTriggered] = useState(false);
    const [isPaginating, setIsPaginating] = useState(false);

    const prevButtonRef = useRef<HTMLButtonElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null);

    const { hits } = useHits<TProductDetails>();
    const {
        nbPages,
        nbHits: totalHits,
        refine: refinePage,
        currentRefinement: currentPage,
    } = usePagination();
    const { status } = useInstantSearch();

    const { value: productHits, isPending } = useTransitionedValue(hits);
    const isLoading = status === 'loading' || status === 'stalled' || isPending;
    const isMobile = useIsMobile();

    useEffect(() => {
        // Set smooth scroll to top when new content list is supplied by Algolia triggered by paging navigation
        if (status === 'idle' && isPaginating && filterBarRef?.current) {
            const rect = filterBarRef.current.getBoundingClientRect();
            const gutterSize = isMobile ? 12 : 16; // TODO: make this dynamic
            const scrollOffset = headerHeight ?? 230;
            const targetPosition = window.scrollY + rect.top - scrollOffset - gutterSize;

            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth',
            });

            setIsPaginating(false);
        }
    }, [status, isPaginating]);

    const handlePageChange = (newPage: number) => {
        setIsPaginating(true);
        refinePage(newPage);

        // We can use setTimeout with 0ms delay to ensure this happens after refinePage
        setTimeout(() => {
            if (prevButtonRef.current && currentPage > newPage) {
                prevButtonRef.current.blur();
            } else if (nextButtonRef.current && currentPage < newPage) {
                nextButtonRef.current.blur();
            }

            productListRef.current?.querySelectorAll('a')?.[0].focus({ preventScroll: true });
        }, 0);
    };

    const handlePrevious = () => {
        handlePageChange(Math.max(0, currentPage - 1));
    };

    const handleNext = () => {
        handlePageChange(Math.min(currentPage + 1, nbPages - 1));
    };

    useEffect(() => {
        if (isEventTriggered) return;

        if (hits.length) {
            const transformedItems = hits.map((hit, index) =>
                _transformProductDetailsToDataLayer(hit, currentPage, index, isPlusMember),
            );

            productsImpressionsEvent('ProductList', 'ProductList', transformedItems);
            setIsEventTriggered(true);
        }
    }, [hits, isPlusMember, query]);

    useEffect(() => {
        if (totalHits) {
            setTotalHits(totalHits);
        }
    }, [totalHits]);

    const renderPaginationControls = () => {
        if (nbPages <= 1 || hits.length === 0) return null;

        const allPages = Array.from({ length: nbPages }, (_, i) => i + 1);

        return (
            <PaginationControls
                className="pagination-controls"
                role="navigation"
                aria-label="Product list pagination"
            >
                <NavigationButton
                    ref={prevButtonRef}
                    color="secondary"
                    onClick={handlePrevious}
                    disabled={currentPage === 0}
                    aria-label={translate('plp.loadPreviousText')}
                    tabIndex={0}
                >
                    <span aria-hidden="true">&larr;</span>
                    {translate('plp.loadPreviousText')}
                    {isLoading && (
                        <LoadingOverlay>
                            <span aria-label="Loading">◝</span>
                        </LoadingOverlay>
                    )}
                </NavigationButton>

                <PageDropdown
                    currentPage={currentPage}
                    totalPages={nbPages}
                    pages={allPages}
                    onPageChange={handlePageChange}
                    showCheckmark={false}
                    aria-label={`Select page, current page ${currentPage + 1} of ${nbPages}`}
                />

                <NavigationButton
                    ref={nextButtonRef}
                    color="secondary"
                    onClick={handleNext}
                    disabled={currentPage === nbPages - 1}
                    aria-label={translate('plp.loadMoreText')}
                    tabIndex={0}
                >
                    {translate('plp.loadMoreText', { amount: itemsPerPage.toString() })}
                    <span aria-hidden="true">&rarr;</span>
                    {isLoading && (
                        <LoadingOverlay>
                            <span aria-label="Loading">◝</span>
                        </LoadingOverlay>
                    )}
                </NavigationButton>
            </PaginationControls>
        );
    };

    return (
        <>
            {!!totalHits && <HitsHeader totalHits={totalHits} />}
            <ProductListWrapper ref={productListRef}>
                <FadeWrapper isLoading={isLoading}>
                    <ProductList
                        hits={productHits}
                        identifier={identifier}
                        itemsPerPage={itemsPerPage}
                    />
                </FadeWrapper>
                <PaginationWrapper>{renderPaginationControls()}</PaginationWrapper>
            </ProductListWrapper>
        </>
    );
};

export default memo(PaginatedHits);
